import React, {Component, useEffect} from 'react';
import {Dropdown} from 'react-bootstrap';
import {Link, useLocation} from 'react-router-dom';

function Navigation() {
    const myRef = React.createRef();
    const location = useLocation();

    useEffect(() => {
        const node = myRef.current;
        $('#side-menu').metisMenu();
    }, [])
    $('#side-menu').metisMenu();


    function activeRoute(routeName) {
        return location.pathname.includes(routeName) ? 'active' : '';
    }

    function secondLevelActive(routeName) {
        return location.pathname.includes(routeName) ? 'nav nav-second-level collapse in' : 'nav nav-second-level collapse';
    }

    return (
        <nav className="navbar-default navbar-static-side" role="navigation">
            <ul className="nav metismenu" id="side-menu" ref={myRef}>
                <li className="nav-header">
                    <div className="dropdown profile-element">
                        <span>
                        </span>
                        <a data-toggle="dropdown" className="dropdown-toggle" href="#">
                            <span className="clear">
                                <span className="block m-t-xs">
                                    <strong className="font-bold">Lite</strong>
                                </span>
                                {/*<span className="text-muted text-xs block">*/}
                                {/*    Example position<b className="caret"></b>*/}
                                {/*</span>*/}
                            </span>
                        </a>
                        <ul className="dropdown-menu animated fadeInRight m-t-xs">
                            <li><a href="#"> Logout</a></li>
                        </ul>
                    </div>
                    <div className="logo-element">
                        Lite
                    </div>
                </li>
                <li className={activeRoute('/users')}>
                    <Link to="/users">
                        <i className="fa fa-users"></i>
                        <span className="nav-label">Пользователи</span>
                    </Link>
                </li>
                <li className={activeRoute('/shifts')}>
                    <Link to="/shifts">
                        <i className="fa  fa-clock-o"></i>
                        <span className="nav-label">Смены</span>
                    </Link>
                </li>
            </ul>

        </nav>
    )
}

export default Navigation
